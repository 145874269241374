<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right" v-if="breadcrumb.length>0">
				<div class="pagetitle">{{breadcrumb[breadcrumb.length-1].name}}</div>
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: item.path }" v-for="(item,index) in breadcrumb" :key="index" v-show="index!=breadcrumb.length-1">{{item.name}}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<div class="panel" style="margin-bottom:60px;">
			<div class="panel-body">


				<div class="fcj_ll" style="align-items: stretch;">
					<div style="flex:1;">
						<div style="padding:30px;">

							<div class="fcj_lr">
								<div class="fcj_ll">
									<div style="padding-right: 10px;">
										楼层平面图
									</div>
									<div>
										<el-select v-model="filter.bid" slot="prepend" size="medium" placeholder="楼栋"
											@change="getfloorinfo(1)" class="searchitem"
											style="width:120px;margin-right:10px;" v-if="res.length>0">
											<el-option v-for="item in res" :key="item.id" :label="item.bname"
												:value="item.id">
											</el-option>
										</el-select>
									</div>
									<div>
										<el-select v-model="filter.fid" slot="prepend" size="medium" placeholder="楼层"
											@change="getoffice(filter.bid,filter.fid)" class="searchitem"
											style="width:120px;margin-right:10px;">
											<el-option v-for="item in floor.res" :key="item.id" :label="item.ordid"
												:value="item.ordid">
											</el-option>
										</el-select>
									</div>
								</div>
								<div class="fcj_ll">
									<div style="margin-right:10px;">
										<el-button type="primary" size="medium" icon="el-icon-plus" @click="newlabel">
											新增房间标签
										</el-button>
									</div>
									<div>
										<el-upload :action="host+'upimg/'" :data="uploaddata" :show-file-list="false"
											list-type="text" ref="uploadfloorpic1" name="upfile"
											:on-success="uploadfloorpicsuccess" :auto-upload="true" :limit="1">

											<el-button type="primary" size="medium" icon="el-icon-picture-outline">
												更换楼层平面图
											</el-button>

										</el-upload>
									</div>
								</div>
							</div>


						</div>
						<div class="fcj_lr" style="margin:0 30px 40px 30px;">
							<div class="fcj_ll" v-if="offices!=''&&offices.building">
								<div style="margin-right:10px;">{{offices.building.bname+' '+offices.fname}}</div>
								<el-button size="mini" icon="el-icon-edit" circle @click="isshowfloorname=true">
								</el-button>
							</div>
							<div class="fcj_ll">
								<div class="fcj_ll">
									<div class="se" style="background-color: #ff6600;"></div>
									<div>出租中</div>
								</div>
								<div class="fcj_ll">
									<div class="se" style="background-color: #397BF0;"></div>
									<div>空置中</div>
								</div>
								<div class="fcj_ll">
									<div class="se" style="background-color: #e6a23c;"></div>
									<div>已预订</div>
								</div>
							</div>
						</div>

						<div v-if="offices.pic">
							<div class="fcj_lc" style="margin:10px 0;">
								<div>箭头位置：</div>
								<div style="margin:0 10px;">
									<el-button-group>
										<el-button type="info" size="small" @click="charrow(0)" plain>左下
										</el-button>
										<el-button type="info" size="small" @click="charrow(1)" plain>右下
										</el-button>
										<el-button type="info" size="small" @click="charrow(2)" plain>左上
										</el-button>
										<el-button type="info" size="small" @click="charrow(3)" plain>右上
										</el-button>
									</el-button-group>
								</div>
							</div>
							<div style="" ref="canvas">
								<div :style="'overflow: hidden;width:'+(mainwh[0]*scale)+'px;height:'+(mainwh[1]*scale)+'px;margin:0 auto;'">
								<div :style="'position: relative;display:inline-block;transform: scale('+scale+');transform-origin:left top'">
									<img :src="host+offices.pic" draggable="false" @load="loadmainpic" ref="mainpic">

									<fcj :whrange="whrange"
										:position="[room.position[0]?parseInt(room.position[0]):255,room.position[1]?parseInt(room.position[1]):0]"
										:scale="scale" v-model="abb" @change="setpoint()" v-for="(room,index) in offices.rooms"
										:key="index">
										<div :class="[room.statistics=='1'?(room.state=='2'?'notsale':(room.state=='3'?'signing':'saled')):'green','point']" @mousedown="chroom(index)"
											:style="{left:(room.position[0])+'px',top:(room.position[1])+'px'}">
											<div style="height:100%;position:relative;">
												<div src="" :class="['image',arrowclass[room.arrow]]"></div>
												<div v-if="acid==index" class="ch"></div>
												<div style="position: relative;">{{room.roomnumber}}
												</div>

											</div>
										</div>
									</fcj>
								</div>
								</div>

							</div>





						</div>
						<div style="margin:10px;background-color: #f8f8f8;padding:10px;border-radius: 8px;"
							v-if="offices.pic&&voidroomlog.length>0">
							<el-table :data="voidroomlog" style="width:100%">
								<el-table-column width="20">
								</el-table-column>
								<el-table-column prop="roomnumber" label="作废房间名称">
								</el-table-column>
								<el-table-column prop="voidtime" label="作废日期">
								</el-table-column>
								<el-table-column label="操作人">
									<template slot-scope="scope">
										{{scope.row.name||scope.row.usern}}
									</template>
								</el-table-column>
								<el-table-column prop="officename" label="操作" width="160">
									<template slot-scope="scope">
										<div class="fcj_ll">
											<div style="margin-right:5px;">
												<el-tooltip class="item" effect="dark" content="查看详情"
													placement="top-start">
													<el-button @click="goinfo(scope.row.id)" size="mini"
														icon="el-icon-view" circle></el-button>
												</el-tooltip>
											</div>
											<div style="position: relative;">
												<el-tooltip class="item" effect="dark" content="楼层平面图"
													placement="top-start">
													<div>
														<el-image
															style="position:absolute;width:28px; height:28px;left:0px;top:0px;opacity: 0;"
															:preview-src-list="[host+scope.row.voidpic]"
															:src="host+scope.row.voidpic"></el-image>
														<el-button size="mini" icon="el-icon-picture-outline" circle>
														</el-button>
													</div>
												</el-tooltip>
											</div>
										</div>


									</template>
								</el-table-column>
							</el-table>
						</div>
						<div v-if="!offices.pic" style="text-align: center;padding:40px 0;">
							<el-upload :action="host+'upimg/'" :data="uploaddata" list-type="text" ref="uploadfloorpic"
								name="upfile" :on-success="uploadfloorpicsuccess" :auto-upload="true" :limit="1">

								<el-button type="primary" icon="el-icon-picture-outline">上传楼层平面图</el-button>
								<div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>

							</el-upload>
						</div>
					</div>
					<div style="width:300px;background-color: #f7f9ff;border-radius: 0 8px 8px 0;padding:40px;">
						<div v-if="acid!==''&&offices.rooms&&offices.rooms.length>0">
							<div v-if="offices.pic">

								<div class="h3">
									房间信息
								</div>
								<div class="redtitle" style="margin-top:30px;">{{offices.rooms[acid].roomnumber}}</div>

							</div>
							<div>
								<div v-if="offices.pic&&!edit">
									<div class="">

										<el-descriptions title="" :column="1" size="medium"
											labelClassName="des-labeloffceinfo" contentClassName="des-content">
											<el-descriptions-item label="门牌名称">
												{{offices.rooms[acid].roomnumber}}
											</el-descriptions-item>
											<el-descriptions-item label="物业类型">
												{{offices.rooms[acid].class_name}}
											</el-descriptions-item>
											<el-descriptions-item label="建筑面积">
												{{offices.rooms[acid].builduparea}}
											</el-descriptions-item>
											<el-descriptions-item label="租金单价">
												{{offices.rooms[acid].price}}
											</el-descriptions-item>
											<el-descriptions-item label="月租金">
												{{offices.rooms[acid].monthprice}}
											</el-descriptions-item>
											<el-descriptions-item label="物业单价">
												{{offices.rooms[acid].property}}
											</el-descriptions-item>
											<el-descriptions-item label="月物业费">
												{{offices.rooms[acid].monthproperty}}
											</el-descriptions-item>
										</el-descriptions>
										<el-descriptions title="" :column="1" size="medium"
											labelClassName="des-labeloffceinfo" contentClassName="des-content">

											<el-descriptions-item label="出租统计">
												{{offices.rooms[acid].statistics==1?'统计':'不统计'}}
											</el-descriptions-item>
											<el-descriptions-item label="展示状态">
												{{offices.rooms[acid].isshow==1?'对外可见':'对外不可见'}}
											</el-descriptions-item>
											<el-descriptions-item label="租赁状态">
												{{offices.rooms[acid].state==1?'出租中':(offices.rooms[acid].state==3?'已预定':'空置中')}}
											</el-descriptions-item>
											<el-descriptions-item label="租赁客户">
												{{offices.rooms[acid].company}}
											</el-descriptions-item>
											<el-descriptions-item label="到期日期">
												{{offices.rooms[acid].endtime}}
											</el-descriptions-item>
											<el-descriptions-item label="房间状态">
												{{offices.rooms[acid].situation}}
											</el-descriptions-item>
											<el-descriptions-item label="装修状态">
												{{offices.rooms[acid].renovation_state==2?'已装修':(offices.rooms[acid].renovation_state==1?'装修中':'无装修')}}
											</el-descriptions-item>
											<el-descriptions-item label="报修状态">
												{{offices.rooms[acid].repair_state==1?'报修中':'无报修'}}
											</el-descriptions-item>


											<el-descriptions-item label="意向客户">
												{{offices.rooms[acid].intention==1?'有':'无'}}
											</el-descriptions-item>
										</el-descriptions>


									</div>
									<div class="fcj_ll">
										<div class="linka blue" @click="goinfo()"><i class="el-icon-document"></i>
											查看详情</div>
										<div class="linka blue" @click="editlabel()"><i class="el-icon-edit"></i>
											编辑</div>
										<el-popover placement="top" width="160" v-model="voidr"
											style="margin-left:10px;">
											<p>确定要作废吗？</p>
											<div style="text-align: right; margin: 0">
												<el-button size="mini" type="text" @click="voidr = false">取消
												</el-button>
												<el-button type="primary" size="mini" @click="voidroom()">确定
												</el-button>
											</div>
											<div class="linka red" slot="reference"><i
													class="el-icon-document-delete"></i>作废</div>
										</el-popover>
										<el-popover placement="top" width="160" v-model="visible"
											style="margin-left:10px;">
											<p>确定删除吗？</p>
											<div style="text-align: right; margin: 0">
												<el-button size="mini" type="text" @click="visible = false">取消
												</el-button>
												<el-button type="primary" size="mini" @click="delok()">确定
												</el-button>
											</div>
											<div class="linka red" slot="reference"><i class="el-icon-delete"></i>删除
											</div>
										</el-popover>

									</div>
									<div style="padding:20px 0;font-size:12px;color:#999;">
										1、新添加房间出错时可删除<br>2、有出租记录的房间只能作废不能删除
									</div>
									<!-- <div style="margin:10px;">
										<div class="fcjtitle">租赁历史记录</div>
										<el-table :data="rentlog" style="width:100%">
											<el-table-column width="20">
											</el-table-column>
											<el-table-column prop="contractnum" label="合同编号">
											</el-table-column>
											<el-table-column prop="customer" label="公司名称">
											</el-table-column>
											<el-table-column prop="officename" label="使用室号">
												<template slot-scope="scope">
													<div>{{scope.row.number}}</div>
												</template>
											</el-table-column>
										</el-table>
										<div class="fcjtitle">室号曾用名</div>
										<el-table :data="namelog" style="width:100%">
											<el-table-column width="20">
											</el-table-column>
											<el-table-column prop="oldname" label="名称">
											</el-table-column>
											<el-table-column prop="dt" label="使用截至日期">
											</el-table-column>
											<el-table-column prop="personal" label="修改人">
											</el-table-column>
										</el-table>
										<div class="fcjtitle">看房备注</div>
										<el-table :data="notelist" style="width:100%">
											<el-table-column width="20">
											</el-table-column>

											<el-table-column prop="number" label="备注">
												<template slot-scope="scope">
													<div>{{scope.row.number}}</div>
												</template>
											</el-table-column>
											<el-table-column prop="company" label="时间">
												<template slot-scope="scope">
													<div>{{scope.row.number}}</div>
												</template>
											</el-table-column>

										</el-table>
									</div>
 -->
								</div>
								<div v-if="offices.pic&&edit">


									<div style="margin:20px 10px 10px ;" class="panel">
										<div class="panel-body pad16"
											style="padding-right:40px;background-color: #f9f9f9;">

											<el-form :model="offices.rooms[acid]" :rules="rules" ref="ruleForm"
												label-width="100px" class="demo-ruleForm" size="mini">
												<el-form-item label="门牌名称" prop="roomnumber">
													<el-input v-model="offices.rooms[acid].roomnumber"></el-input>
												</el-form-item>
												<el-form-item label="物业类型" prop="type">
													<el-radio-group v-model="offices.rooms[acid].type">
														<el-radio name="purpose" v-for="(item,index) in roomtype"
															:key="index" :label="item.fid">
															{{item.class_name}}
														</el-radio>
													</el-radio-group>
												</el-form-item>

												<el-form-item label="建筑面积" prop="builduparea">
													<el-input v-model="offices.rooms[acid].builduparea"
														style="width:200px;"><template
															slot="append">m<sup>2</sup></template></el-input>
												</el-form-item>
												<el-form-item label="套内面积" prop="buildinarea">
													<el-input v-model="offices.rooms[acid].buildinarea"
														style="width:200px;"><template
															slot="append">m<sup>2</sup></template></el-input>
												</el-form-item>
												<el-form-item label="层高" prop="height">
													<el-input v-model="offices.rooms[acid].height" style="width:200px;">
														<template slot="append">m</template>
													</el-input>
												</el-form-item>
												<el-form-item label="租金单价" prop="price">
													<el-input v-model="offices.rooms[acid].price" style="width:200px;">
														<template slot="append">元/天/m<sup>2</sup></template></el-input>
												</el-form-item>
												<el-form-item label="物业单价" prop="property">
													<el-input v-model="offices.rooms[acid].property"
														style="width:200px;"><template
															slot="append">元/天/m<sup>2</sup></template></el-input>
												</el-form-item>

												<el-form-item label="月租金" prop="monthprice" style="width:200px;">
													<el-input v-model="offices.rooms[acid].monthprice">
													</el-input>
												</el-form-item>
												<el-form-item label="月物业费" prop="monthproperty" style="width:200px;">
													<el-input v-model="offices.rooms[acid].monthproperty">
													</el-input>
												</el-form-item>
												<el-form-item label="年租金" prop="yearprice" style="width:200px;">
													<el-input v-model="offices.rooms[acid].yearprice">
													</el-input>
												</el-form-item>
												<el-form-item label="年物业费" prop="yearproperty" style="width:200px;">
													<el-input v-model="offices.rooms[acid].yearproperty">
													</el-input>
												</el-form-item>

												<el-form-item label="出租统计" prop="statistics">
													<el-radio-group v-model="offices.rooms[acid].statistics">
														<el-radio-button label="1" name="statistics">统计
														</el-radio-button>
														<el-radio-button label="0" name="statistics">不统计
														</el-radio-button>
													</el-radio-group>
												</el-form-item>
												<el-form-item label="展示状态" prop="isshow">
													<el-radio-group v-model="offices.rooms[acid].isshow">
														<el-radio-button label="1" name="isshow">对外可见</el-radio-button>
														<el-radio-button label="0" name="isshow">对外隐藏</el-radio-button>
													</el-radio-group>
												</el-form-item>


												<el-form-item label="补充说明" prop="note">
													<el-input type="textarea" v-model="offices.rooms[acid].note"
														maxlength="255" show-word-limit :autosize="{ minRows: 5}">
													</el-input>
												</el-form-item>
												<div style="padding:20px 0;margin-left: 100px;">
													<el-upload :action="host+'upimg/'" list-type="picture-card"
														ref="upload" name="upfile" :on-success="uploadsuccess"
														:file-list="fileList" :auto-upload="false"
														:on-change="uploadchange">
														<i slot="default" class="el-icon-plus"></i>
														<div slot="file" slot-scope="{file}">
															<img class="el-upload-list__item-thumbnail" :src="file.url"
																alt="">
															<span class="el-upload-list__item-actions">
																<span class="el-upload-list__item-preview"
																	@click="handlePictureCardPreview(file)">
																	<i class="el-icon-zoom-in"></i>
																</span>
																<span v-if="!disabled"
																	class="el-upload-list__item-delete"
																	@click="handleDownload(file)">
																	<i class="el-icon-download"></i>
																</span>
																<span v-if="!disabled"
																	class="el-upload-list__item-delete"
																	@click="handleRemove(file)">
																	<i class="el-icon-delete"></i>
																</span>
															</span>
														</div>

													</el-upload>
													<el-dialog :visible.sync="dialogVisible">
														<img width="100%" :src="dialogImageUrl" alt="">
													</el-dialog>

												</div>

												<el-form-item>
													<el-button type="primary" @click="submitForm('ruleForm')">保存
													</el-button>
													<el-button @click="cancel()">取消</el-button>
												</el-form-item>
											</el-form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog :visible.sync="isshowfloorname" width="600px">
			<el-form ref="floorform" :model="floorform" :rules="floorformrules" label-width="150px">

				<el-form-item label="楼栋名称" prop="bname">
					{{offices.building?offices.building.bname:''}}
				</el-form-item>



				<el-form-item label="楼层名称" prop="fname">
					<el-input v-model="floorform.fname" style="width:200px;"></el-input>
				</el-form-item>
				<!-- <el-form-item label="出租统计" prop="type">
					<el-radio-group v-model="floorform.type">
						<el-radio name="type" v-for="(item,index) in flooringtype"
							:key="index" :label="item.fid">
							{{item.class_name}}
						</el-radio>
					</el-radio-group>
				</el-form-item> -->
				<el-form-item style="margin-top:50px;">

					<el-button type="primary" icon="el-icon-document" @click="savefloorname">保存</el-button>

					<el-button style="margin-left:10px;" @click="cancelfloor">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import fcj from '../components/fcj.vue'
	export default {
		components: {
			fcj
		},
		data() {
			return {
				uploaddata: {
					pusern: '',
					type:'upfloor',
					backapi:1,
					sys:localStorage.getItem('sys')
				},
				breadcrumb: [],
				isshowfloorname: false,
				floorform: {
					fname: '',
				},

				floorformrules: {
					fname: [{
						required: true,
						message: '请输入楼层名称',
						trigger: 'blur'
					}],
				},
				filter: {
					bid: '',
					fid: '',
				},
				abb: [],
				whrange:[0,70,2000,0],
				mainwh:[],
				canvaswh:[],
				scale:1,
				
				edit: false,
				notelist: [],
				officetype: [{
					value: '',
					label: '全部类型'
				}, {
					value: '1',
					label: '办公'
				}, {
					value: '2',
					label: '商铺'
				}, {
					value: '3',
					label: '仓储'
				}, {
					value: '4',
					label: '其他'
				}],

				dialogImageUrl: '',
				dialogVisible: false,
				disabled: false,
				fileList: [],
				floorpic: '',
				host: '',
				bi: 1,
				acid: '',
				res: [],
				floor: {},
				offices: '',
				arrowclass: ['lb', 'rb', 'lt', 'rt'],
				arrow: 0,
				acbuildingid: -1,
				floorid: 0,
				acfloorid: '',
				labelname: false,
				visible: false,
				voidr: false,
				rentlog: [],
				namelog: [],
				voidroomlog: [],
				situation:[],
				ruleForm: {
					roomnumber: '',
					builduparea: '',
					buildinarea: '',
					height: '',
					price: '',
					monthprice: '',
					monthproperty: '',
					yearprice: '',
					yearproperty: '',
					statistics: '',
					isshow: '',
					note: ''
				},
				rules: {
					type: [{
						required: true,
						message: '请选择物业类型',
						trigger: 'change'
					}],
					name: [{
						required: true,
						message: '请输入门牌号',
						trigger: 'blur'
					}],
					builduparea: [{

						required: true,
						message: '请输入建筑面积',
						trigger: 'change'
					}, {
						pattern: /^\d+(\.\d+)?$/,
						message: '输入数字',
					}],
					buildinarea: [{

						required: true,
						message: '请输入套内面积',
						trigger: 'change'
					}, {
						pattern: /^\d+(\.\d+)?$/,
						message: '输入数字',
					}],
					height: [{

						required: true,
						message: '请输入层高',
						trigger: 'change'
					}],
					price: [{
						required: true,
						message: '租金单价',
						trigger: 'change'
					}, {
						pattern: /^\d+(\.\d+)?$/,
						message: '输入数字',
					}],
					property: [{
						required: true,
						message: '物业单价',
						trigger: 'change'
					}, {
						pattern: /^\d+(\.\d+)?$/,
						message: '输入数字',
					}],
					statistics: [{
						required: true,
						message: '出租统计',
						trigger: 'blur'
					}],
					isshow: [{
						required: true,
						message: '是否对外可见',
						trigger: 'blur'
					}]
				}
			}
		},
		beforeRouteEnter(to, from, next) {
			next(vm => {
				vm.$root.myrouter = vm.$breadcrumb(vm.$root.myrouter, to)
			})
		},
		mounted: function() {
			this.uploaddata.pusern = localStorage.getItem('pusern');
			this.breadcrumb = this.$root.myrouter
			this.filter.bid = this.$route.query.bid ? this.$route.query.bid : '';
			this.filter.fid = this.$route.query.fid ? this.$route.query.fid : '';

			this.host = this.$host
			


			this.getbuilding();

		},
		methods: {
			loadmainpic(){
				
				this.canvaswh=[this.$refs.canvas.offsetWidth,this.$refs.canvas.offsetHeight];
				this.mainwh=[this.$refs.mainpic.offsetWidth,this.$refs.mainpic.offsetHeight];
				this.whrange=[0,this.$refs.mainpic.offsetWidth-10,this.$refs.mainpic.offsetHeight-10,0];
				this.mu();
			},
			mu(){
				// if(this.canvaswh[0]/this.canvaswh[1]>this.mainwh[0]/this.mainwh[1]){
				// 	//画布很宽,以高度缩放
				// 	this.scale=this.canvaswh[1]/this.mainwh[1];
				// }else{
				//	this.scale=this.canvaswh[0]/this.mainwh[0];
				//}
				this.scale=750/this.mainwh[0];
			},
			cancelfloor() {
				this.isshowfloorname = false
			},
			savefloorname() {
				this.$refs['floorform'].validate((valid) => {
					if (valid) {
						this.$ajax({
							url: 'building/',
							data: {
								do: 'updatefloorname',
								id: this.offices.id,
								fname: this.floorform.fname
							}
						}).then(res => {
							if (res.error == 0) {
								this.isshowfloorname = false;
								this.offices.fname = this.floorform.fname
							} else {
								this.$alert(res.msg, '提示', {
									confirmButtonText: '确定'
								});
							}

						});
					}
				})
			},
			test() {
				return false
			},
			voidroom() {
				this.$ajax({
					url: 'room/',
					data: {
						do: 'void',
						roomid: this.offices.rooms[this.acid].id
					}
				}).then(res => {
					if (res.error == 0) {
						this.getoffice(this.filter.bid, this.filter.fid)
					}
					this.$alert(res.msg, '提示', {
						confirmButtonText: '确定'
					});
				});

			},
			goinfo(v) {
				var id = '';
				if (v) {
					id = v
				} else {
					id = this.offices.rooms[this.acid].id;
				}
				this.$router.push('/officeinfo?bid=' + this.acbuildingid + '&fid=' + this.acfloorid + '&id=' + id)
			},
			handleRemove(file) {
				for (var i = 0; i < this.fileList.length; i++) {
					if (this.fileList[i].uid == file.uid) {
						this.fileList.splice(i, 1);
						break;
					}
				}
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			handleDownload(file) {},
			uploadsuccess(response, file, fileList) {
				this.offices.rooms[this.acid].pics = [];
				this.fileList = fileList;
				for (var i = 0; i < this.fileList.length; i++) {
					if (this.fileList[i].response == undefined) {
						break;
					} else {
						this.offices.rooms[this.acid].pics.push(this.fileList[i].response.fpath)
					}
				}
				if (i == this.fileList.length) {
					this.sub();
				}

				//this.buluform.pics.push(response);
			},
			uploadchange(file, fileList) {
				this.fileList = fileList;
			},
			uploadfloorpicsuccess(response) {
				this.floorpic = response.fpath
				this.chpic()
			},
			delok: function() { //删除房间

				if (this.offices.rooms[this.acid].id == 0) {
					var bd = this.offices
					bd.floor.splice(this.acid, 1);
					var acid = this.acid - 1;
					if (acid < 0) {
						acid = 0;
					}
					this.offices = bd
					this.acid = acid

				} else {
					this.$ajax({
						url: 'room/',
						data: {
							do: 'del',
							roomid: this.offices.rooms[this.acid].id
						}
					}).then(res => {
						if (res.error == 0) {
							var f = this.offices;
							f.rooms.splice(this.acid, 1);
							var acid = this.acid - 1;
							if (acid < 0) {
								acid = 0;
							}
							this.offices = f
							this.acid = acid
						}

						this.$message({
							message: res.msg,
							type: 'warning'
						})

						this.visible = false


					})

				}
			},
			newlabel: function() {
				if (this.offices.pic == '') {
					this.$message({
						'message': '请选上传楼层平面图',
						'type': 'warning'
					})

				} else {
					this.$router.push('office?bid=' + this.filter.bid + '&fid=' + this.filter.fid)
				}
				// this.$prompt('输入房间编号', '提示', {
				// 	confirmButtonText: '确定',
				// 	cancelButtonText: '取消',
				// 	inputPattern: /^[^\s]+$/,
				// 	inputErrorMessage: '不能为空'
				// }).then(({
				// 	value
				// }) => {
				// 	this.addlabel(value);
				// 	this.edit = true;
				// }).catch(() => {

				// });
			},
			editlabel() {
				this.$router.push('office?bid=' + this.filter.bid + '&fid=' + this.filter.fid + '&rid=' + this.offices
					.rooms[this.acid].id)
				this.edit = true;
			},
			addlabel: function(v) {
				this.ruleForm.roomnumber = v;
				var bd = this.offices;
				var fl = {
					position: [0, 0],
					building: this.offices.buildid,
					floor: this.offices.ordid,

					roomnumber: v,
					buildinarea: 0,
					builduparea: 0,
					monthprice: 0,
					property: 0,
					monthproperty: 0,
					price: 0,
					state: 2,
					note: "",

					id: 0,
					arrow: 0,
					contractid: 0,
					height: 0,
					mainpic: "",
					pics: [],
					type: "1",
				};
				this.fileList = []
				bd.rooms.push(fl);
				this.acid = bd.rooms.length - 1;
				this.building = bd

			},
			sub: function() {
				if (this.offices.rooms.length == 0) {
					this.$message({
						message: '请选创建标签',
						type: 'warning'
					})
					return;
				}

				this.$ajax({
					url: 'room/',
					data: {
						'do': 'update',
						roomnumber: this.offices.rooms[this.acid].roomnumber,
						builduparea: this.offices.rooms[this.acid].builduparea,
						type: this.offices.rooms[this.acid].type,
						buildinarea: this.offices.rooms[this.acid].buildinarea,
						price: this.offices.rooms[this.acid].price,
						monthprice: this.offices.rooms[this.acid].monthprice,
						property: this.offices.rooms[this.acid].property,
						monthproperty: this.offices.rooms[this.acid].monthproperty,
						state: this.offices.rooms[this.acid].state,
						note: this.offices.rooms[this.acid].note,
						building: this.offices.rooms[this.acid].building,
						height: this.offices.rooms[this.acid].height,
						floor: this.offices.rooms[this.acid].floor,
						position: this.offices.rooms[this.acid].position,

						statistics: this.offices.rooms[this.acid].statistics,
						isshow: this.offices.rooms[this.acid].isshow,
						pics: this.offices.rooms[this.acid].pics,
						id: this.offices.rooms[this.acid].id
					}
				}).then(res => {
					if (res.error == 0) {
						this.offices.rooms[this.acid] = res.data.res
						this.$message({
							message: res.msg,
							type: 'warning'
						})
					}

				})
			},
			chpic() {
				this.$ajax({
					url: 'room/',
					data: {
						'do': 'uppic',
						'path': this.floorpic,
						'id': this.floorid
					}
				}).then(res => {
					if (res.error == 0) {
						this.offices.pic = this.floorpic
						if (this.$refs.uploadfloorpic) {
							this.$refs.uploadfloorpic.clearFiles();
						}

						if (this.$refs.uploadfloorpic1) {
							this.$refs.uploadfloorpic1.clearFiles();
						}
					}
					this.$message({
						message: res.msg
					})
				})

			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.fileList.length == 0) {
							this.sub();
						} else {
							this.offices.rooms[this.acid].pics = [];
							for (var i = 0; i < this.fileList.length; i++) {
								if (this.fileList[i].response == undefined) {
									break;
								} else {
									this.offices.rooms[this.acid].pics.push(this.fileList[i].response)
								}
							}
							if (i == this.fileList.length) {
								this.sub();
							} else {
								this.$refs.upload.submit();
							}
						}
					} else {
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			cancel() {
				this.edit = false;
			},
			charrow: function(v) {

				if (this.offices.rooms[this.acid].state == 0) {
					return;
				}
				this.arrow = v
				this.offices.rooms[this.acid].arrow = v;
				this.setpoint();
			},
			setpoint: function() {

				var f = this.offices;

				if (f.rooms[this.acid].state == 0) {
					return;
				}

				var x = this.abb[0];
				var y = this.abb[1];

        // 立即更新前端数据
        this.$set(this.offices.rooms[this.acid], 'position', [x, y]);

				if (this.offices.rooms[this.acid].id > 0) {
					this.$ajax({
						url: 'room/',
						data: {
							do: 'updatepoint',
							position: [x, y],
							arrow: this.arrow,
							id: this.offices.rooms[this.acid].id
						}
					}).then(res => {

          })
				} else {
					this.offices.rooms[this.acid].position = [x, y]
				}
			},
			chroom(v) {
				this.acid = v;
				sessionStorage.setItem('roomindex', v);
				this.fileList = [];
				if (this.offices.rooms[v].pics.length > 0) {
					for (var i = 0; i < this.offices.rooms[v].pics.length; i++) {
						this.fileList.push({
							name: '',
							url: this.host + this.offices.rooms[v].pics[i],
							response: this.offices.rooms[v].pics[i]
						})
					}
				}

				this.arrow = this.offices.rooms[v].arrow;
				this.edit = false;
				// this.rentlog=[];
				// this.namelog=[];
				// if(this.offices.rooms[this.acid].id>0){
				// 	this.$ajax({
				// 		url: 'floor/',
				// 		data: {
				// 			'roomid': this.offices.rooms[this.acid].id,
				// 		}
				// 	}).then(res => {
				// 		if(res.rentlog.c>0){
				// 			this.rentlog=res.rentlog.res
				// 		}
				// 		if(res.namelog.c>0){
				// 			this.namelog=res.namelog.res
				// 		}
				// 	})
				// }
			},
			getoffice(b, f) {
				if (b) {
					sessionStorage.removeItem('roomindex')
				}
				if (f) {
					sessionStorage.setItem('floorid', f)
				} else {
					if (sessionStorage.getItem('floorid')) {
						this.filter.fid = sessionStorage.getItem('floorid')
					}
				}
				this.$ajax({
					url: 'floor/',
					data: {
						'bid': b ? b : this.filter.bid,
						'do': 'floor',
						'fid': f ? f : this.filter.fid
					}
				}).then(res => {
					this.offices = res;
					this.roomtype = res.roomtype.res;
					if (this.offices.rooms.length > 0) {
						if (this.offices.rooms[0].pics.length > 0) {
							for (var i = 0; i < this.offices.rooms[0].pics.length; i++) {
								this.fileList.push({
									name: '',
									url: this.host + this.offices.rooms[0].pics[i],
									response: this.offices.rooms[0].pics[i]
								})
							}
						}
						if (sessionStorage.getItem('roomindex')) {
							this.acid = sessionStorage.getItem('roomindex');
						} else {
							this.acid = this.offices.rooms.length - 1;
						}
					} else {

						this.fileList = [];
						this.acid = '';
					}
					if (res.voidroom.c > 0) {
						this.voidroomlog = res.voidroom.res
					} else {
						this.voidroomlog = []
					}
					this.situation=res.situation;
					this.floorform.fname = res.fname
					this.acfloorid = res.ordid
					this.floorid = res.id
				})
			},
			getfloorinfo(v) {
				this.$ajax({
					url: 'floor/',
					data: {
						'buildingid': this.filter.bid
					},
				}).then(res => {
					this.acbuildingid = this.filter.bid;
					this.acfloorid = res.res[res.c - 1].ordid;
					this.floor = res;
					this.offices = {};
					if (v) { //切换楼，默认选最底层
						sessionStorage.removeItem('roomindex')
						sessionStorage.removeItem('floorid')
						sessionStorage.setItem('buildid', v)
						this.filter.fid = res.res[res.c - 1].ordid;
					} else {
						if (this.filter.fid == '') {
							this.filter.fid = res.res[res.c - 1].ordid;
						}
					}
					this.getoffice()
				})
			},
			getbuilding() {
				this.$ajax({
					url: 'building/',
					data: {
						'do': 'list'
					},
				}).then(res => {
					this.res = res.res;
					if (this.filter.bid == '') {
						this.filter.bid = this.res[0].id;
					}
					this.acbuildingid = this.filter.bid;
					this.getfloorinfo();
				})
			}
		}
	};
</script>

<style scoped>
	.green{
		background-color: #00cc00;
	}
	.signing{
		background-color: #e6a23c;
	}
	.se {
		width: 10px;
		height: 10px;
		margin-right: 10px;
		margin-left: 10px;
		border-radius: 50%;
	}

	.roominfobox {
		margin: 10px;
		background-color: #fff;
		border-radius: 5px;
		padding: 5px;
	}

	.tj {
		text-align: center;
		padding: 15px 0;
		font-size: 12px;
		border-radius: 10px;
		margin: 10px;
		background-color: #efefef;
	}

	.num {
		font-size: 20px;
		margin-top: 10px;
	}

	.tabl,
	.tablt {
		display: flex;
	}

	.floorname {
		width: 70px;
	}

	.lou {
		padding: 10px;
		font-weight: bold;
		transform: translateY(50%);
		text-align: center;
	}

	.tablt div {
		flex: 1;
		padding: 10px;
		text-align: center;
		border-left: 1px dashed #ddd;
		border-bottom: 1px dashed #ddd;
	}

	.tabl div {
		border-left: 1px dashed #ddd;
		padding: 5px;
		flex: 1;
		text-align: center;
		min-height: 31px;
		font-size: 12px;

		word-break: break-all;
	}

	.dd {
		background-color: #fff;
		border-radius: 7px;
		margin: 15px;
		font-size: 13px;
		box-shadow: 0 0 8px #ddd;
		cursor: pointer;
	}


	.acbuild {
		background-color: #b3b4d5;
		color: #fff;
	}


	.point {
		height: 25px;
		color: #fff;
		font-size: 12px;
		border-radius: 5px;
		box-sizing: border-box;
		padding: 5px;
		cursor: pointer;
		transform: scale(1.8) left top;
	}


	.ch {
		background-image:url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjE2IiBoZWlnaHQ9IjIwIj4NCiAgPHBhdGggZD0iTTUgMCBMNSAxMCBMMCAxMCBMOCAyMCBMMTYgMTAgTDExIDEwIEwxMSAwIg0KICBzdHlsZT0iZmlsbDojMDAwO3N0cm9rZTojYWFhO3N0cm9rZS13aWR0aDowIiBzdHJva2UtbGluZWNhcD0icm91bmQiIC8+DQo8L3N2Zz4=');
		width: 16px;
		height: 20px;
		position: absolute;
		left: 50%;
		margin-left:-8px;
		top: -20px;
		z-index: 10;
	}

	.markbg {
		position: fixed;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		z-index: 100;
		background: rgba(0, 0, 0, 0.3);
	}

	.pics {
		width: 100px;
	}

	.btns {
		font-size: 12px;
		align-items: center;
	}



	.po {
		position: absolute;
		width: 32px;
		height: 40px;
		text-align: center;
		line-height: 33px;
		background-size: cover;
	}

	.h180 {
		height: 54px
	}

	.h132 {
		height: 66px
	}
</style>
