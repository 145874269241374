import Vue from 'vue'
import uilt from './assets/uilt.js'
import ajax from './assets/public.js'
import App from './App.vue'
import store from './store'


import * as echarts from 'echarts'

// Vue.prototype.$regionDataPlus = regionDataPlus
import ElementUi from 'element-ui'
import "element-ui/lib/theme-chalk/index.css"
import formCreate from '@form-create/element-ui'
Vue.use(formCreate)


import "./assets/common.css";
Vue.prototype.$echarts = echarts
Vue.prototype.$setpath = ajax.setpath
Vue.prototype.$ajax = ajax.created
Vue.prototype.$fast = ajax.fast
Vue.prototype.$Msgbox = ajax.MessageBox
Vue.prototype.$host = ajax.path
Vue.prototype.$breadcrumb=ajax.breadcrumb
Vue.prototype.$pageno = ajax.pageno
Vue.config.productionTip = false
Vue.use(uilt)
Vue.use(ElementUi);
import router from './router.js'
new Vue({
	router,
	store,
	data:function(){
		return {
			myrouter:[]
		}
	},
	render: h => h(App),
}).$mount('#app')



