<template>
	<div>
		<div class="breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">
					<div class="pagetitle">首页</div>
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<el-row :gutter="15">
			<el-col :span="12">

				<div class="panel">
					<div class="panel-body" style="padding:20px 20px 0 20px;">

						<div class="fcj_lr" v-if="res" style="margin:0 10px;align-items: flex-end;">

							<div class="ptitle">行业分布统计</div>
							<div style="font-size:30px;"><span class="tjtitle">客户统计</span>{{res.customer_number}}</div>

						</div>
						<div>
							<div class="fcj_ll">
								<div>
									<div id="myChart" :style="{width: '460px', height: '280px'}"></div>
								</div>
							</div>

						</div>

					</div>
				</div>
			</el-col>
			<el-col :span="12">
				<div class="panel">
					<div class="panel-body" style="padding:20px 20px 0 20px;">

						<div class="fcj_lr " v-if="res" style="margin:0 10px;">

							<div class="ptitle">客户来源统计</div>
							<div style="font-size:30px;"><span class="tjtitle">客户统计</span>{{res.customer_number}}</div>

						</div>
						<div>
							<div class="fcj_ll">
								<div>
									<div id="myChart1" :style="{width: '460px', height: '280px'}"></div>
								</div>
							</div>

						</div>

					</div>
				</div>
			</el-col>
		</el-row>
		<el-row :gutter="15" v-if="res">
			<el-col :span="24">

				<div class="panel" style="padding:20px;">
					<div class="panel-header ptitle">
						合同统计
					</div>
					<div class="panel-body">
						<div class="fcj_lr" style="flex-grow:1">
							<div class="fcj_lb littleblock" :style="'flex:1;background:#F2F4FF;'">
								<div>
									<div class="num numred">{{res.contract_number}}</div>
									<div>合同总数量</div>

								</div>
								<div @click="gocontract()" style="cursor: pointer;">
									<div class="num">{{res.endtime}}</div>
									<div>30天内到期</div>

								</div>
								<div @click="gocontract(1)" style="cursor: pointer;">
									<div class="num">{{res.outtime}}</div>
									<div>已过期</div>

								</div>
							</div>
							<div class="fcj_lb littleblock" :style="'flex:1;background:#F2F4FF;'">
								<div>
									<router-link to="contract?state=1">
										<div class="num">{{res.contract_state['1']?res.contract_state['1'].a:0}}</div>
										<div>预签合同</div>
									</router-link>
								</div>
								<div>
									<router-link to="contract?state=0">
										<div class="num">{{res.contract_state['0']?res.contract_state['0'].a:0}}</div>
										<div>正常合同</div>
									</router-link>
								</div>
								<div>
									<router-link to="contract?state=3">
										<div class="num">{{res.contract_state['3']?res.contract_state['3'].a:0}}</div>
										<div>退租合同</div>
									</router-link>
								</div>
							</div>

							<div class="fcj_lb littleblock" :style="'flex:1;background:#F2F4FF;'">
								<div>
									<router-link to="contract?thisyear=1&sign=1">
										<div class="num">{{res.contract_qian}}</div>
										<div>今年新签</div>
									</router-link>
								</div>

								<div>
									<router-link to="contract?thisyear=1&sign=2">
										<div class="num">{{res.contract_back}}</div>
										<div>今年退租</div>
									</router-link>
								</div>
							</div>

						</div>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-row :gutter="15" v-if="res">
			<el-col :span="24">
        <div class="panel" style="padding-bottom: 20px">
          <div class="panel-header ptitle">
            费用统计
          </div>
          <div class="center">
            <div class="b">
              <span class="demonstration text">月</span>
              <el-date-picker
                  v-model="month"
                  type="month"
                  placeholder="选择月"
                  value-format="yyyy-MM"
                  @change="changemonth"
              >
              </el-date-picker>
            </div>
            <div class="text">
              或
            </div>
            <div class="b">
              <span class="demonstration text">年</span>
              <el-date-picker
                  v-model="year"
                  type="year"
                  value-format="yyyy"
                  @change="changeyear"
                  placeholder="选择年">
              </el-date-picker>
            </div>
          </div>

          <div class="panel-body" style="padding-bottom: 20px">
              <div class="fcj_ll">
                  <!-- 第一块：基础费用 -->
                  <div class="fcj_lb littleblock" :style="'flex:1;background:#F2F4FF;'">
                      <div>
                          <div class="num numred">{{statics.should.total_money}}元</div>
                          <div>应收总金额</div>
                      </div>
                      <div>
                          <div class="num numred">{{statics.should.rent_money}}元</div>
                          <div>应收租金</div>
                      </div>
                      <div>
                          <div class="num numred">{{statics.should.property_money}}元</div>
                          <div>应收物业费</div>
                      </div>
                  </div>

                  <!-- 第二块：押金及其他 -->
                  <div class="fcj_lb littleblock" :style="'flex:1;background:#F2F4FF;'">
                      <div>
                          <div class="num numred">{{statics.should.deposit_money}}元</div>
                          <div>应收押金</div>
                      </div>
                      <div>
                          <div class="num numred">{{statics.should.water_money}}元</div>
                          <div>应收水电</div>
                      </div>
                      <div>
                          <div class="num numred">{{statics.should.other_money}}元</div>
                          <div>应收其他</div>
                      </div>
                  </div>
              </div>
          </div>

          <div class="panel-body" style="padding-bottom: 20px">
            <div class="fcj_ll">
              <!-- 第一块：基础费用 -->
              <div class="fcj_lb littleblock" :style="'flex:1;background:#F2F4FF;'">
                <div>
                  <div class="num numred">{{statics.real.total_money}}元</div>
                  <div>实收总金额</div>
                </div>
                <div>
                  <div class="num numred">{{statics.real.rent_money}}元</div>
                  <div>实收租金</div>
                </div>
                <div>
                  <div class="num numred">{{statics.real.property_money}}元</div>
                  <div>实收物业费</div>
                </div>
              </div>

              <!-- 第二块：押金及其他 -->
              <div class="fcj_lb littleblock" :style="'flex:1;background:#F2F4FF;'">
                <div>
                  <div class="num numred">{{statics.real.deposit_money}}元</div>
                  <div>实收押金</div>
                </div>
                <div>
                  <div class="num numred">{{statics.real.water_money}}元</div>
                  <div>实收水电</div>
                </div>
                <div>
                  <div class="num numred">{{statics.real.other_money}}元</div>
                  <div>实收其他</div>
                </div>
              </div>
            </div>
          </div>

          <div class="panel-body" style="padding: 10px 20px;box-sizing: border-box">
            <div class="info">注：</div>
            <div class="info">1. 这里的应收指的是<span style="font-weight: 700;font-style: italic;font-size: 20px;color: #aa1111">最迟付款日</span>在所选时间范围内的所有账单应收金额总和</div>
            <div class="info">2. 这里的实收指的是所有账单<span style="font-weight: 700;font-style: italic;font-size: 20px;color: #aa1111">付款日期</span>在所选范围内的已付款金额总和</div>
            <div class="info">3.月份和年份只能<span style="font-weight: 700;font-style: italic;font-size: 20px;color: #aa1111">二选一</span>，要么按年，要么按月</div>
          </div>
        </div>

			</el-col>
		</el-row>
		<!-- 
		<div>
			<div class="panel">
				<div class="panel-body" style="padding:40px">
					<div class="fcj_lr" style="margin-bottom: 20px;">
						<div>
							<el-input placeholder="输入关键字进行过滤" v-model="filterText">
							</el-input>
						</div>
						<div>
							<el-checkbox-group v-model="tabPosition" size="small">
								<el-checkbox-button label="租户">租户</el-checkbox-button>
								<el-checkbox-button label="自用">自用</el-checkbox-button>
								<el-checkbox-button label="公用">公用</el-checkbox-button>
							</el-checkbox-group>
						</div>
					</div>

					<el-tree :data="data" node-key="id" default-expand-all @node-drag-start="handleDragStart"
						@node-drag-enter="handleDragEnter" @node-drag-leave="handleDragLeave"
						@node-drag-over="handleDragOver" @node-drag-end="handleDragEnd" @node-drop="handleDrop"
						draggable :allow-drop="allowDrop" :allow-drag="allowDrag" :expand-on-click-node="false"
						ref="tree">
						<div :style="data.zu==3?'color:#0066ff;font-weight:bold;':(data.zu==1?'color:#ff6600;font-weight:bold;':'')" slot-scope="{node,data}">
							{{data.label}}<i class="el-icon-warning" v-if="data.over==9"></i> <span
								v-if="data.tp">（度数：{{data.du}}）</span></div>
					</el-tree>
				</div>
			</div>
		</div> -->
	</div>
</template>

<script>
	export default {
		data() {
			return {
				host: this.$host,
				res: '',
				myChart: '',
				myChart1: '',
				amount: 0,
				time: '',
				paymoney: 0,
				notpay: 0,
				contract_classm: {},
				month: '',
        year:'',
				tabPosition: [],
				filterText: '',
        statics:{},
				data: [{
					id: 1,
					label: '高配置间1',
					children: [{
						id: 4,
						label: '北集装箱区域',
						children: [{
							id: 94,
							tp: 1,
							du: 1234,
							label: '301电表'
						}, {
							id: 112,
							tp: 1,
							du: 2456,
							label: '302电表'
						}]
					}, {
						id: 5,
						label: '东集装箱区域',
						children: [{
							id: 95,
							zu: 3,
							label: '公共照明'
						}, {
							id: 115,
							over: 1,
							zu: 1,
							label: '301电表'
						}, {
							id: 10,
							zu: 1,
							label: '302电表'
						}, {
							id: 12,
							zu: 2,
							label: '备用电表'
						}, {
							id: 135,
							zu: 3,
							label: '智慧大屏'
						}, {
							id: 13,
							label: '桥空间',
							children: [{
								id: 961,
								label: '108表'
							}, {
								id: 962,
								label: '107表'
							}, {
								id: 963,
								label: '106表'
							}]
						}]
					}, {
						id: 6,
						label: '开关',
						children: [{
							id: 96,
							label: '109表'
						}, {
							id: 106,
							label: '备用电表'
						}]
					}]
				}, {
					id: 2,
					label: '高配置间2',
					children: [{
						id: 5,
						label: '二级 2-1'
					}, {
						id: 6,
						label: '二级 2-2'
					}]
				}, {
					id: 3,
					label: '高配置间3',

				}],
				defaultProps: {
					children: 'children',
					label: 'label'
				}
			}
		},
		mounted: function() {
			const script=document.createElement('script');
			script.src='http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js';
			script.onload=()=>{
				this.initWxLogin();
				
			}
			document.head.appendChild(script)
			this.ini();
			this.host = this.$host
			this.month = new Date();
      //转换为yyyy-mm
      this.month = this.initMonth(this.month);
      this.inidata();
    },
    computed: {
      // 计算属性
      parkKey() {
        return this.$store.state.parkKey
      }
    },
		watch: {
			filterText(val) {
				this.$refs.tree.filter(val);
			},
      parkKey(newval){
        this.inidata()
        this.ini()
      }
		},
		methods: {
			filterNode(value, data) {
				if (!value) return true;
				return data.label.indexOf(value) !== -1;
			},
      initMonth(m){
        //将日期格式的m转换为月份
        let year = m.getFullYear();
        let month = m.getMonth() + 1;
        if(month < 10){
          month = '0' + month;
        }
        return year + '-' + month;
      },
      changemonth(val){
        this.month = val;
        this.year = "";
        this.$nextTick(() => {
          this.inidata();
        });
      },
      changeyear(val){
        this.year = val;
        this.month = "";
        this.$nextTick(() => {
          this.inidata();
        });
      },
			handleDragStart(node, ev) {
				console.log('drag start', node);
			},
			handleDragEnter(draggingNode, dropNode, ev) {
				console.log('tree drag enter: ', dropNode.label);
			},
			handleDragLeave(draggingNode, dropNode, ev) {
				console.log('tree drag leave: ', dropNode.label);
			},
			handleDragOver(draggingNode, dropNode, ev) {
				console.log('tree drag over: ', dropNode.label);
			},
			handleDragEnd(draggingNode, dropNode, dropType, ev) {
				console.log('tree drag end: ', dropNode && dropNode.label, dropType);
			},
			handleDrop(draggingNode, dropNode, dropType, ev) {
				console.log('tree drop: ', dropNode.label, dropType);
			},
			allowDrop(draggingNode, dropNode, type) {
				if (dropNode.data.label === '二级 3-1') {
					return type !== 'inner';
				} else {
					return true;
				}
			},
			allowDrag(draggingNode) {
				return draggingNode.data.label.indexOf('三级 3-2-2') === -1;
			},
      // 获取统计信息
      inidata(){
        let date = ''
        if(this.month !== ''){
          date = this.month
        }else if(this.year !== ''){
          date = this.year
        }else{
          return false
        }
        this.$fast({
          url:'api/get_zhw_fee',
          data:{
            date:date
          }
        }).then(res=>{
          if(res.code === 200){
            this.statics = res.data
          }else{
            this.$message.error(res.msg)
          }
        })
      },

			gd() {
				this.ini();
			},
			gocontract(v) {
				if (v) {
					this.$router.push('/contract?outtime=1')
				} else {
					this.$router.push('/contract?endtime=30')
				}
			},

			ini() {
				this.$ajax({
					url: 'adm_index/',
					data: {
						month: this.month
					},

				}).then(res => {
					this.res = res.data;
					this.time = res.data.time;
					if (this.myChart != null && this.myChart != "" && this.myChart != undefined) {
						//this.myChart.dispose();
					} else {
						this.myChart = this.$echarts.init(document.getElementById('myChart'))
						this.myChart1 = this.$echarts.init(document.getElementById('myChart1'))
					}
					var dd = [];
					for (var i = 0; i < this.res.tradetype.length; i++) {
						dd[i] = {
							value: this.res.tradetype[i].a,
							name: res.data.tradetypemap[this.res.tradetype[i].tradetype] ? res.data
								.tradetypemap[this.res.tradetype[i].tradetype].class_name : '其他'
						};

					}
					var dc = [];
					for (i = 0; i < this.res.source.length; i++) {
						dc[i] = {
							value: this.res.source[i].a,
							name: res.data.sourcemap[this.res.source[i].source] ? res.data.sourcemap[this.res
								.source[i].source].class_name : '其他'
						};
					}
					this.res.pei = this.res.pei.div(100).toFixed(2);
					this.res.deposite = this.res.deposite.div(100).toFixed(2);
					this.amount = (res.data.contract_all.amountmoney / 100).toFixed(2);
					this.paymoney = (res.data.contract_all.paymoney / 100).toFixed(2);
					this.notpay = ((res.data.contract_all.amountmoney - res.data.contract_all.paymoney) / 100)
						.toFixed(2);
					this.contract_classm = res.data.contract_classm;


					// 绘制图表
					this.myChart.setOption({

						title: {
							left: 'center'
						},
						tooltip: {
							trigger: 'item'
						},
						legend: {
							orient: 'vertical',
							right: 10,
							top: 20,
							bottom: 20,
						},
						series: [{
							name: '行业分布',
							type: 'pie',
							radius: '50%',
							center: ['40%', '45%'],
							data: dd,
							emphasis: {
								itemStyle: {
									shadowBlur: 10,
									shadowOffsetX: 0,
									shadowColor: 'rgba(0, 0, 0, 0.5)'
								}
							}
						}]


					});

					// 绘制图表
					this.myChart1.setOption({
						title: {
							left: 'center'
						},
						tooltip: {
							trigger: 'item'
						},
						legend: {
							orient: 'vertical',
							right: 10,
							top: 20,
							bottom: 20,
						},
						series: [{
							name: '客户来源',
							type: 'pie',
							radius: '50%',
							center: ['40%', '45%'],
							data: dc,
							emphasis: {
								itemStyle: {
									shadowBlur: 10,
									shadowOffsetX: 0,
									shadowColor: 'rgba(0, 0, 0, 0.5)'
								}
							}
						}]
					});
					this.myChart.on('click', (p) => {
						console.log(this.res.tradetype[p.dataIndex].tradetype)
						localStorage.setItem('pageno', '');
						this.$router.push('customer?tradetypeid=' + this.res.tradetype[p.dataIndex]
							.tradetype)
					})
					this.myChart1.on('click', (p) => {
						console.log(this.res.source[p.dataIndex].source)
						localStorage.setItem('pageno', '');
						this.$router.push('customer?sourceid=' + this.res.source[p.dataIndex].source)
					})
				})
			}
		},
	}
</script>

<style scoped>
	.ptitle {
		font-size: 16px;
		color: #121212;
		font-weight: 700;
	}

	.littleblock a {
		color: #96A1B3;
	}

	.tjtitle {
		font-size: 14px;
		color: #96A1B3;
		margin-right: 10px;
		font-weight: 200;
	}
  .center{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
  .text{
    font-size: 20px;
    font-weight: 700;
  }
  .b{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex-direction: column;
  }

</style>
